// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';
import '@splidejs/splide/css/core';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';
// Simple & lightweight library to create smooth & beautiful animations when you scroll.
import lax from 'lax.js';


// ================================================
// SUI Components
// ================================================

import MobileNav from '@sgroup/mobile-nav';
import ClickToggle from '@sgroup/click-toggle';


// ================================================
// Custom Modules
// ================================================
import './modules/carousel';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

import AnimeAnimation from '@components/AnimeAnimation.vue';
import TextChanger from '@components/TextChanger.vue';
import InViewport from '@components/InViewport.vue';

// Import all globally-available components here. Be responsible please.
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    TransitionRoot,
    TransitionChild,
} from '@headlessui/vue';

import SplideCarousel from '@components/SplideCarousel.vue';

import {
    SplideTrack,
    SplideSlide,
} from '@splidejs/vue-splide';

createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
        HuiTransitionRoot: TransitionRoot,
        HuiTransitionChild: TransitionChild,
        SplideCarousel,
        SplideCarouselTrack: SplideTrack,
        SplideCarouselSlide: SplideSlide,
        TextChanger,
        InViewport,
        AnimeAnimation,
    },

    data() {
        return {
            navActive: false,
        };
    },
}).mount('#app');
