<script>
import {
    ref, watch, watchEffect, onMounted, h,
} from 'vue';
import { useElementBounding } from '@vueuse/core';
import anime from 'animejs/lib/anime';

export default {
    props: {
        as: {
            type: String,
            default: 'div',
        },
        options: {
            type: Object,
            default() {
                return {};
            },
        },
        delay: {
            type: Number,
            default: 0,
        },
        duration: {
            type: Number,
            default: 500,
        },
        margin: {
            type: Number,
            default: 200,
        },
    },

    setup(props, { slots }) {
        const item = ref(null);

        const animation = ref(null);

        onMounted(() => {
            const options = {
                targets: item.value,
                duration: props.duration,
                delay: props.delay,
                autoplay: false,
                ...props.options,
            };

            animation.value = anime(options);
        });

        const visible = ref(false);
        const { top } = useElementBounding(item);

        const unwatch = watch(top, () => {
            visible.value = top.value <= (window.innerHeight - props.margin || document.documentElement.clientHeight);
        });

        watchEffect(() => {
            // If the element is within the viewport
            if (visible.value) {
                // Trigger the animation
                animation.value.play();

                // Clean up watcher
                unwatch();
            }
        });

        return () => {
            return h(props.as, {
                ref: item,
            }, slots.default());
        };
    },
};
</script>
