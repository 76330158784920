<script>
import { h, ref, watchEffect } from 'vue';

import { useElementVisibility } from '@vueuse/core';

export default {
    name: 'InViewport',

    setup(_, { slots }) {
        const target = ref(null);
        const visibility = useElementVisibility(target);
        const inViewport = ref(false);

        watchEffect(() => {
            if (visibility.value) {
                setTimeout(() => {
                    inViewport.value = true;
                }, 500);
            }
        });

        return () => {
            return h('div', {
                ref: target,
            }, slots.default({
                inViewport: inViewport.value,
            }));
        };
    },
};
</script>
