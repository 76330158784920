<template>
    <span class="border-b-4 border-white transition duration-500 ease-in-out">{{ activeWord }}</span>
</template>

<script>
export default {
    name: 'TextChanger',

    props: {
        words: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            index: 0,
            activeWord: this.words[0],
        };
    },

    mounted() {
        setInterval(() => {
            this.$el.style.opacity = 0;

            this.$el.addEventListener('transitionend', this.change);
        }, 5000);
    },

    unmounted() {
        this.$el.removeEventListener('transitionend', this.change);
    },

    methods: {
        change() {
            if (this.$el.style.opacity === '0') {
                this.updateWord();
            }

            this.$el.style.opacity = 1;
        },

        updateWord() {
            if (this.index !== this.words.length - 1) {
                this.index++;

                return this.activeWord = this.words[this.index];
            }

            this.index = 0;
            return this.activeWord = this.words[this.index];
        },
    },
};
</script>
