<template>
    <Splide ref="splide">
        <slot :next-slide="nextSlide" :prev-slide="prevSlide"></slot>
    </Splide>
</template>

<script>
import { ref } from 'vue';
import { Splide } from '@splidejs/vue-splide';

export default {
    name: 'SplideCarousel',

    components: {
        Splide,
    },

    setup() {
        const splide = ref(null);

        const prevSlide = () => {
            splide.value.go('<');
        };

        const nextSlide = () => {
            splide.value.go('>');
        };

        return {
            splide,
            prevSlide,
            nextSlide,
        };
    },
};
</script>
